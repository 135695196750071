:root {
	--fancybox-accent-color: #007bff;
	--toastify-color-info: rgb(2, 136, 209) !important;
	--toastify-color-success: rgb(46, 125, 50) !important;
	--toastify-font-family: inherit !important;
}

body {
	margin: 0;
	font-family: "Roboto", sans-serif;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;

	-webkit-text-size-adjust: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button.SRLCloseButton,
button.SRLExpandButton,
button.SRLZoomOutButton {
	border-radius: 50% !important;
}

.fancybox__container {
	z-index: 1400 !important;
}

.fancybox__thumbs .carousel__slide .fancybox__thumb::after {
	border-width: 3px !important;
}

.HW_badge {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	line-height: 1 !important;
}

#html5qr-code-full-region {
	padding: 12px 12px 0 !important;
	border: 0 !important;
}

#html5qr-code-full-region__dashboard_section_csr {
	display: flex !important;
	flex-wrap: wrap !important;
	justify-content: center !important;
	align-items: flex-end !important;
	font-size: 14px;

	span {
		margin: 0 !important;
	}
}

#html5-qrcode-select-camera,
#html5-qrcode-button-camera-start,
#html5-qrcode-button-camera-stop,
#html5-qrcode-button-camera-permission,
#html5-qrcode-anchor-scan-type-change,
#html5-qrcode-button-file-selection {
	border: 0;
	background-color: #007bff !important;
	border-radius: 4px !important;
	border: 0;
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	color: white;
	cursor: pointer;
	display: block;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-size: 0.9375rem;
	font-size: 14px;
	font-weight: 500;
	margin: 4px !important;
	letter-spacing: 0.02857em;
	line-height: 1.75;
	padding: 6px 14px !important;
	text-decoration: none !important;
	text-transform: uppercase;
}

#html5-qrcode-select-camera {
	background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=) no-repeat 95% 50%;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}

#html5-qrcode-anchor-scan-type-change {
	display: none !important;
}
